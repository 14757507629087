(() => {
    window.dataLayer.push({
        event: 'page_view',
        eventModel: {
            page_location: window.location.href,
            page_title: document.title,
            page_referrer: document.referrer,
            account_name: themeDisplay.getAccountName(),
            user_id: themeDisplay.getUserId(),
            ccid: themeDisplay.getAccountUuid(),
            login_status: themeDisplay.isSignedIn()
        }
    });
})();
